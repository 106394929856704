<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มจังหวัด
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="mainWarehouseCode" class="required form-label col-sm-10"
            >รหัสกลุ่มสินค้าหลัก</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div> -->
        <div class="row d-flex justify-content-center">
          <label for="mainWarehouseName" class="required form-label col-sm-10"
            >ชื่อจังหวัด</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import addressApi from "@/api/address/";

export default {
  setup() {
    document.title = "CHOMTHANA | เพิ่มกลุ่มสินค้าหลัก";
  },
  data: () => ({
    loading: false,
    form: {
      code: "",
      name: "",
      status: "1",
    },
  }),
  methods: {
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          companyId: parseInt(localStorage.getItem("companyId")),
        };
        // productGroups
        createResponse = await addressApi.province.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 2300,
          }).then(() => {
            this.$router.push({
              path: "/config/province/index",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.name 
      ? (this.isValid = false) 
      // :!this.form.code 
      // ? (this.isValid = false) 

      : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/province/index",
        query: {},
      });
    },
  },
};
</script>
